import { Button } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import { IconChevronRight } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

import { activeTaskAtom, taskModuleAtomFamily, useResetModule, useSelectMolule } from '@store/Workflows';
import { stageAtom } from '@store/atoms/StageState';

import {
  ACTIVE_MODULES_BY_CATEGORY,
  COMING_SOON_MODULE_ATTRIBUTES,
  LIMITED_MODULE_ATTRIBUTES,
  MODULE_ATTRIBUTES,
  MODULE_CATEGORY_LABELS,
} from '@constants/Workflows';

function WorkflowModuleSelector() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const taskModule = useRecoilValue(taskModuleAtomFamily(activeTask));
  const stage = useRecoilValue(stageAtom);
  const selectedModuleDetail = MODULE_ATTRIBUTES[taskModule];
  const selectModule = useSelectMolule();
  const resetModule = useResetModule();

  const onReset = () => {
    // eslint-disable-next-line no-alert
    const confirmChange = window.confirm(
      'Warning: All changes will be lost.\nAre you sure you want to change this Action?'
    );
    if (confirmChange) {
      resetModule();
    }
  };

  if (taskModule && selectedModuleDetail) {
    return (
      <div className="form-container">
        <div className="tile-button selected">
          <div className="tile-button__icons">
            {selectedModuleDetail.IconComponent && <selectedModuleDetail.IconComponent className="tile-button__icon" />}
            {selectedModuleDetail.logoUrl && (
              <img src={selectedModuleDetail.logoUrl} alt={selectedModuleDetail.title} className="tile-button__logo" />
            )}
          </div>
          <p>
            <span className="description" dangerouslySetInnerHTML={{ __html: selectedModuleDetail.description }} />
          </p>
          <Button variant="light" onClick={onReset}>
            Change
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      {Object.entries(ACTIVE_MODULES_BY_CATEGORY).map(([category, modules]) => {
        if (modules.length === 0) {
          return null;
        }
        return (
          <div key={category}>
            <h6 className="mb-4">{MODULE_CATEGORY_LABELS[category]}</h6>
            <div className="tile-buttons">
              {modules
                .filter((module) => !module.limited || stage !== 'stage')
                .map(({ slug, title, description, logoUrl }) => {
                  return (
                    <ButtonElement key={slug} bsPrefix="tile-button" handleEvent={() => selectModule(slug)}>
                      <div className="tile-button__icons">
                        {logoUrl && <img src={logoUrl} alt={title} className="tile-button__logo" />}
                      </div>
                      <p>
                        <span className="description" dangerouslySetInnerHTML={{ __html: description }} />
                      </p>
                      <div className="tile-button__arrow">
                        <IconChevronRight />
                      </div>
                    </ButtonElement>
                  );
                })}
            </div>
          </div>
        );
      })}
      {stage === 'stage' && (
        <div>
          <h6>Production only</h6>
          <div className="tile-buttons settings--disabled">
            {Object.entries(LIMITED_MODULE_ATTRIBUTES).map(([slug, { title, logoUrl }]) => {
              return (
                <ButtonElement key={slug} bsPrefix="tile-button">
                  <div className="tile-button__icons">
                    {logoUrl && <img src={logoUrl} alt={title} className="tile-button__logo" />}
                  </div>
                  <p>
                    <strong>{title}</strong>
                  </p>
                </ButtonElement>
              );
            })}
          </div>
        </div>
      )}
      <div>
        <div className="tile-buttons settings--disabled">
          <h6>Coming soon</h6>
          {Object.entries(COMING_SOON_MODULE_ATTRIBUTES).map(([slug, { title, logoUrl }]) => {
            return (
              <ButtonElement key={slug} bsPrefix="tile-button">
                <div className="tile-button__icons">
                  {logoUrl && <img src={logoUrl} alt={title} className="tile-button__logo" />}
                </div>
                <p>
                  <strong>{title}</strong>
                </p>
              </ButtonElement>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default WorkflowModuleSelector;
