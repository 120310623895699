import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { getTemplate, getTemplateRaw } from '@api/services/template';

import InteractiveConsole from '@components/console/InteractiveConsole';

import { useHydrateStoreFromTemplate } from '@store/atoms/EditState';
import { templateState } from '@store/selectors/TemplateSelectors';

function Automate() {
  const { id } = useParams();
  const hydrateStore = useHydrateStoreFromTemplate();

  const setTemplateState = useSetRecoilState(templateState);
  const [rawTemplate, setRawTemplate] = useState(null);

  useEffect(() => {
    const getTemplateData = async () => {
      try {
        const data = await getTemplate(id);
        const rawData = await getTemplateRaw(id);
        await hydrateStore(data.template);
        setTemplateState(data);
        setRawTemplate(rawData);
      } catch (error) {
        console.error(error);
      }
    };

    if (id) {
      getTemplateData();
    }
  }, [id, hydrateStore, setTemplateState]);

  return (
    <div className="max-w-screen-xl mx-auto p-10">
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-lg font-bold mb-8">
          Send an API request to create a video. Poll the API or use a webhook to get notified when the video is ready.
        </p>
        <InteractiveConsole product="edit-api" compact initialPayload={rawTemplate?.template || {}} />
      </div>
    </div>
  );
}

export default Automate;
