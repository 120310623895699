import { S3_ACL, S3_REGIONS } from '@constants/S3';
import { VIMEO_COMMENT_OPTIONS, VIMEO_EMBED_OPTIONS, VIMEO_VIEW_OPTIONS } from '@constants/Vimeo';

export const EMPTY_ACTION_STEP = {
  type: 'action',
  module: '',
  provider: '',
  inputs: [],
  outputs: [],
  options: [],
  connected: false,
};

export const TASK_DEFAULTS = {
  id: '',
  name: '',
  module: '',
  type: 'action',
  provider: null,
  connected: false,
  options: {},
  mappings: {
    inputs: {},
    outputs: {},
  },
};

export const TRIGGER_OPTIONS = {
  'shotstack:csv': {
    label: 'CSV Upload',
    description: 'Upload a CSV file to start the workflow.',
    type: 'manual',
    logoUrl: '/img/provider/emblems/shotstack-csv-trigger.webp',
  },
  'shotstack:webhook': {
    label: 'Webhook',
    description: 'Send a POST request with an API key to start the workflow.',
    type: 'automated',
    logoUrl: '/img/provider/emblems/shotstack-webhook-trigger.webp',
  },
};

export const MODULE_CATEGORIES_ORDERED = [
  'render',
  'ai',
  'tts',
  'ttt',
  'tti',
  'ttv',
  'processing',
  'enhance',
  'metadata',
  'destination',
];

export const MODULE_CATEGORY_LABELS = {
  render: 'Media Rendering',
  processing: 'Media Processing',
  destination: 'Destinations',
  metadata: 'Metadata',
  ai: 'Generative AI',
  tts: 'Speech Generation',
  ttt: 'Text Generation',
  tti: 'Image Generation',
  ttv: 'Video Generation',
  enhance: 'Media Enhancement',
};

export const MODULE_CATEGORY_GROUPS = {
  image: ['ai'],
  video: ['render', 'processing', 'ai'],
  audio: ['ai', 'enhance'],
  text: ['ai'],
  metadata: ['metadata'],
  destination: ['destination'],
};

export const getModuleCategoryGroup = (category) => {
  const group = Object.entries(MODULE_CATEGORY_GROUPS).find(([, categories]) => categories.includes(category));
  return group ? group[0] : null;
};

export const MODULE_MAP = {
  'shotstack:render-template': {
    attributes: {
      type: 'video',
      ext: 'mp4',
      outputKey: 'url',
      category: 'render',
      provider: 'shotstack',
      label: 'Render Studio Template',
      title: 'Render Studio Template',
      description: 'Render a video from a Studio template',
      logoUrl: `/img/provider/emblems/shotstack-render.webp`,
    },
    options: {
      templateId: '',
    },
    inputs: {},
  },
  'shotstack:http': {
    attributes: {
      type: 'http',
      outputKey: 'status',
      category: 'destination',
      provider: 'shotstack',
      label: 'HTTP Module',
      title: 'HTTP Module',
      description: 'Send a HTTP request',
      logoUrl: `/img/provider/emblems/Shotstack.png`,
    },
    options: {
      url: 'https://api.shotstack.io',
      method: 'POST',
      contentType: 'application/json',
    },
    inputs: {
      payload: [],
    },
  },
  'shotstack:text-to-speech': {
    attributes: {
      type: 'audio',
      ext: 'mp3',
      outputKey: 'url',
      category: 'ai',
      provider: 'shotstack',
      label: 'Convert Text to Speech',
      title: 'Convert Text to Speech',
      description: 'Convert Text to Speech',
      logoUrl: `/img/provider/emblems/shotstack-text-to-speech.webp`,
    },
    options: {
      voice: '',
    },
    inputs: {
      text: '',
    },
  },
  'elevenlabs:text-to-speech': {
    attributes: {
      type: 'audio',
      ext: 'mp3',
      outputKey: 'url',
      category: 'ai',
      provider: 'elevenlabs',
      label: 'ElevenLabs Text to Speech',
      title: 'ElevenLabs Text to Speech',
      description: 'Convert text to speech using the ElevenLabs text-to-speech service',
      logoUrl: `/img/provider/emblems/ElevenLabs.png`,
      inactive: true,
    },
  },
  'shotstack:destination': {
    attributes: {
      type: 'url',
      outputKey: 'url',
      category: 'destination',
      provider: 'shotstack',
      label: 'Shotstack Hosting',
      title: 'Shotstack Hosting',
      description: 'Host a file using the Shotstack Hosting service',
      logoUrl: `/img/provider/emblems/shotstack-hosting.webp`,
    },
    inputs: {
      url: '',
    },
  },
  'tiktok:destination': {
    attributes: {
      type: 'url',
      outputKey: 'url',
      category: 'destination',
      provider: 'tiktok',
      label: 'Tiktok',
      title: 'Tiktok',
      description: 'Send a video to Tiktok',
      logoUrl: `/img/provider/emblems/TikTok.png`,
    },
    inputs: {
      url: '',
    },
  },
  's3:destination': {
    attributes: {
      type: 'url',
      outputKey: 'url',
      category: 'destination',
      provider: 's3',
      label: 'S3 Bucket',
      title: 'S3 Bucket',
      description: 'Send a file to an AWS S3 bucket',
      logoUrl: `/img/provider/emblems/AWS.png`,
    },
    options: {
      region: '',
      bucket: '',
      prefix: undefined,
      filename: '',
      acl: 'public-read',
    },
    inputs: {
      url: '',
    },
  },
  'google-cloud-storage:destination': {
    attributes: {
      type: 'url',
      outputKey: 'url',
      category: 'destination',
      provider: 'google-cloud',
      label: 'Google Cloud Storage',
      title: 'Google Cloud Storage',
      description: 'Send a file to Google Cloud Storage',
      logoUrl: `/img/provider/emblems/GoogleCloud.png`,
    },
    options: {
      prefix: undefined,
      filename: '',
      bucket: '',
    },
    inputs: {
      url: '',
    },
  },
  'mux:destination': {
    attributes: {
      type: 'url',
      outputKey: 'url',
      category: 'destination',
      provider: 'mux',
      label: 'MUX Video Platform',
      title: 'MUX Video Platform',
      description: 'Send a file to the Mux video platform',
      logoUrl: `/img/provider/emblems/MUX.png`,
    },
    options: {
      policyPublic: false,
      policySigned: false,
      passthrough: '',
    },
    inputs: {
      url: '',
    },
  },
  'google-drive:destination': {
    attributes: {
      type: 'url',
      outputKey: 'url',
      category: 'destination',
      provider: 'google-drive',
      label: 'Google Drive',
      title: 'Google Drive',
      description: 'Send a file to Google Drive',
      logoUrl: `/img/provider/emblems/GoogleDrive.png`,
      limited: true,
    },
    options: {
      filename: '',
      folderId: '',
    },
    inputs: {
      url: '',
    },
  },
  'vimeo:destination': {
    attributes: {
      type: 'url',
      outputKey: 'url',
      category: 'destination',
      provider: 'vimeo',
      label: 'Vimeo',
      title: 'Vimeo',
      description: 'Send a file to Vimeo',
      logoUrl: `/img/provider/emblems/Vimeo.png`,
      limited: true,
    },
    options: {
      name: '',
      description: '',
      view: 'anybody',
      embed: 'public',
      comments: 'anybody',
    },
    inputs: {
      url: '',
    },
  },
  'shotstack:text-generator': {
    attributes: {
      type: 'text',
      outputKey: 'text',
      category: 'ai',
      provider: 'shotstack',
      label: 'Shotstack Text Generator',
      title: 'Shotstack Text Generator',
      description: 'Generate text using the Shotstack AI Text Generator',
      logoUrl: `/img/provider/emblems/shotstack-text-generator.webp`,
      inactive: true,
    },
    inputs: {
      prompt: '',
    },
  },
  'openai:text-generator': {
    attributes: {
      type: 'text',
      outputKey: 'text',
      category: 'ai',
      provider: 'openai',
      label: 'OpenAI Text Generator',
      title: 'OpenAI Text Generator',
      description: 'Generate text using the OpenAI API',
      logoUrl: `/img/provider/emblems/OpenAI.png`,
      inactive: true,
    },
    options: {
      model: undefined,
      systemPrompt: undefined,
    },
    inputs: {
      prompt: '',
    },
  },
  'shotstack:text-to-image': {
    attributes: {
      type: 'image',
      ext: 'png',
      outputKey: 'url',
      category: 'ai',
      provider: 'shotstack',
      label: 'Create an Image',
      title: 'Create an Image',
      description: 'Create an Image',
      logoUrl: `/img/provider/emblems/shotstack-text-to-image.webp`,
    },
    options: {
      width: undefined,
      height: undefined,
    },
    inputs: {
      prompt: '',
    },
  },
  'stability-ai:text-to-image': {
    attributes: {
      type: 'image',
      ext: 'png',
      outputKey: 'url',
      category: 'ai',
      provider: 'stability-ai',
      label: 'Generate Image with Stability AI',
      title: 'Generate Image with Stability AI',
      description: 'Generate an image using the Stability Ai text-to-image service',
      logoUrl: `/img/provider/emblems/StabilityAI.png`,
      inactive: true,
    },
    options: {
      width: undefined,
      height: undefined,
      steps: undefined,
    },
    inputs: {
      prompt: '',
    },
  },
  'd-id:text-to-avatar': {
    attributes: {
      type: 'video',
      ext: 'png',
      outputKey: 'url',
      category: 'ai',
      provider: 'd-id',
      label: 'D-ID',
      title: 'D-ID',
      description: 'Generate an avatar using the D-ID text-to-avatar service',
      logoUrl: `/img/provider/emblems/D-ID.png`,
      inactive: true,
    },
    options: {
      avatar: undefined,
      background: undefined,
    },
    inputs: {
      text: '',
    },
  },
  'shotstack:metadata': {
    attributes: {
      type: 'metadata',
      // TODO: update outputKey to support multiple outputs
      outputKey: 'duration',
      category: 'metadata',
      provider: 'shotstack',
      label: 'Get Duration',
      title: 'Get Duration',
      description: 'Get duration for video and audio files',
      logoUrl: `/img/provider/emblems/shotstack-duration.webp`,
      inactive: true,
    },
  },
  'shotstack:image-to-video': {
    attributes: {
      type: 'video',
      ext: 'mp4',
      outputKey: 'url',
      category: 'ai',
      provider: 'shotstack',
      label: 'Create a Video from an Image',
      title: 'Create a Video from an Image',
      description: 'Create a Video from an Image',
      logoUrl: `/img/provider/emblems/shotstack-image-to-video.webp`,
    },
    options: {
      guidanceScale: undefined,
      motion: undefined,
    },
    inputs: {
      imageUrl: '',
    },
  },
  'heygen:text-to-avatar': {
    attributes: {
      type: 'video',
      ext: 'mp4',
      outputKey: 'url',
      category: 'ai',
      provider: 'heygen',
      label: 'HeyGen',
      title: 'HeyGen',
      description: 'Generate an avatar using the HeyGen text-to-avatar service',
      logoUrl: `/img/provider/emblems/HeyGen.png`,
      inactive: true,
    },
    options: {
      avatar: undefined,
      avatarStyle: 'normal',
      voice: undefined,
      background: '#ffffff',
      test: false,
    },
    inputs: {
      text: '',
    },
  },
  'shotstack:video-transform': {
    attributes: {
      type: 'video',
      ext: 'mp4',
      outputKey: 'url',
      category: 'processing',
      provider: 'shotstack',
      label: 'Video Transformation',
      title: 'Video Transformation',
      description: 'Transform a video',
      logoUrl: `/img/provider/emblems/shotstack-video-processing.webp`,
    },
    options: {
      format: undefined,
      width: undefined,
      height: undefined,
      fit: undefined,
      quality: undefined,
      fps: undefined,
    },
    inputs: {
      url: '',
    },
  },
  'shotstack:transcript': {
    attributes: {
      type: 'transcription',
      ext: 'srt',
      outputKey: 'url',
      category: 'processing',
      provider: 'shotstack',
      label: 'Generate Transcript',
      title: 'Generate Transcript',
      description: 'Generate SRT transcript',
      logoUrl: `/img/provider/emblems/shotstack-generate-transcript.webp`,
    },
    options: {},
    inputs: {
      url: '',
    },
  },
  'shotstack:image-transform': {
    attributes: {
      type: 'image',
      ext: 'png',
      outputKey: 'url',
      category: 'processing',
      provider: 'shotstack',
      label: 'Image Transformation',
      title: 'Image Transformation',
      description: 'Transform an image',
      logoUrl: `/img/provider/emblems/shotstack-image-processing.webp`,
      inactive: true,
    },
    options: {
      format: undefined,
      width: undefined,
      height: undefined,
      fit: undefined,
      quality: undefined,
    },
    inputs: {
      url: '',
    },
  },
  'dolby:audio-enhance': {
    attributes: {
      type: 'audio',
      ext: 'mp3',
      outputKey: 'url',
      category: 'enhance',
      provider: 'dolby',
      label: 'Dolby Audio Enhance',
      title: 'Dolby Audio Enhance',
      description: 'Enhance audio with Dolby Audio',
      logoUrl: `/img/provider/emblems/Dolby.png`,
      inactive: true,
    },
    options: {
      preset: undefined,
    },
    inputs: {
      url: '',
    },
  },
  'youtube:destination': {
    attributes: {
      comingSoon: true,
      title: 'YouTube',
      logoUrl: `/img/provider/emblems/YouTube.png`,
    },
  },
};

export const PANEL_TITLES = {
  trigger: 'Trigger',
  action: 'Action',
  end: 'End workflow',
};

export const PANEL_INTRO_CARDS = [
  {
    type: 'trigger',
    title: 'Triggers',
    description:
      'Every workflow begins with a trigger—the event that sets the workflow in motion. Trigger a workflow using a CSV file upload or a webhook. More triggers are coming soon.',
    buttonText: 'Select a Trigger',
  },
  {
    type: 'action',
    title: 'Actions',
    description:
      'Actions are the steps that make up the workflow. Actions can be used to create, transform, enhance, generate and analyse all kinds of media files. Actions are chained together to create a workflow.',
    buttonText: 'Add an Action',
  },
];

export const noOptionsMessage = (type) => () => {
  switch (type) {
    case 'data':
      return 'No data available.';
    case 'template':
      return 'No templates found.';
    default:
      return 'No options available.';
  }
};

export const MODULE_DESTINATION_FIELDS = {
  'shotstack:destination': [{ type: 'inputs', label: 'File Url', name: 'url', placeholder: '' }],
  'tiktok:destination': [{ type: 'inputs', label: 'File Url', name: 'url', placeholder: '' }],
  's3:destination': [
    { type: 'inputs', label: 'File Url', name: 'url', placeholder: '' },
    { type: 'inputs', label: 'File Name', name: 'filename', placeholder: 'my-file', isOptional: true },
    { type: 'seperator' },
    { type: 'options', name: 'region', fieldType: 'select', options: S3_REGIONS },
    { type: 'options', name: 'bucket', placeholder: 'my-bucket', fieldType: 'text' },
    { type: 'options', name: 'prefix', placeholder: 'my-renders (optional)', fieldType: 'text' },
    { type: 'options', name: 'acl', fieldType: 'select', options: S3_ACL },
  ],
  'google-cloud-storage:destination': [
    { type: 'inputs', label: 'File Url', name: 'url', placeholder: '' },
    { type: 'inputs', label: 'File Name', name: 'filename', placeholder: 'my-file', isOptional: true },
    { type: 'seperator' },
    { type: 'options', name: 'bucket', placeholder: 'my-bucket', fieldType: 'text' },
    { type: 'options', name: 'prefix', placeholder: 'my-folder (optional)', fieldType: 'text' },
  ],
  'mux:destination': [
    { type: 'inputs', label: 'File Url', name: 'url', placeholder: '' },
    { type: 'inputs', name: 'passthrough', fieldType: 'select', isOptional: true },
    { type: 'seperator' },
    { type: 'options', label: 'Public Playback Policy', name: 'policyPublic', fieldType: 'checkbox' },
    { type: 'options', label: 'Signed Playback Policy', name: 'policySigned', fieldType: 'checkbox' },
  ],
  'google-drive:destination': [
    { type: 'inputs', label: 'File Url', name: 'url', placeholder: '' },
    { type: 'inputs', label: 'File Name', name: 'filename', placeholder: 'my-file', isOptional: true },
    { type: 'inputs', label: 'Folder ID', name: 'folderId', placeholder: 'my-folder-id', isOptional: true },
    { type: 'seperator' },
  ],
  'vimeo:destination': [
    { type: 'inputs', label: 'File Url', name: 'url', placeholder: '' },
    { type: 'inputs', label: 'Name', name: 'name', placeholder: 'My Vimeo Video', isOptional: true },
    {
      type: 'inputs',
      label: 'Description',
      name: 'description',
      placeholder: 'My Vimeo Video Description',
      isOptional: true,
    },
    { type: 'seperator' },
    { type: 'options', name: 'view', fieldType: 'select', options: VIMEO_VIEW_OPTIONS },
    { type: 'options', name: 'embed', fieldType: 'select', options: VIMEO_EMBED_OPTIONS },
    { type: 'options', name: 'comments', fieldType: 'select', options: VIMEO_COMMENT_OPTIONS },
  ],
};

export const MODULE_SLUGS = Object.keys(MODULE_MAP);
export const MODULE_ENTRIES = Object.entries(MODULE_MAP);
export const COMING_SOON_MODULES_ENTRIES = Object.entries(MODULE_MAP).filter(
  ([, { attributes }]) => attributes.comingSoon
);

export const LIMITED_MODULES_ENTRIES = Object.entries(MODULE_MAP).filter(([, { attributes }]) => attributes.limited);

/* returns: [['module:slug', { attributes, inputs, outputs }]] */
export const ACTIVE_MODULE_ENTRIES = Object.entries(MODULE_MAP).filter(
  ([, { attributes }]) => !attributes.comingSoon && !attributes.inactive
);

/* returns: { 'module:slug': module.attributes, ... } */
export const MODULE_ATTRIBUTES = ACTIVE_MODULE_ENTRIES.reduce((acc, [key, { attributes }]) => {
  acc[key] = attributes;
  return acc;
}, {});

/* returns: { 'module:slug': module.options, ... } */
export const MODULE_OPTIONS = ACTIVE_MODULE_ENTRIES.reduce((acc, [key, { options }]) => {
  acc[key] = options;
  return acc;
}, {});

/* returns: { 'module:slug': module.inputs, ... } */
export const MODULE_INPUTS = ACTIVE_MODULE_ENTRIES.reduce((acc, [key, { inputs }]) => {
  acc[key] = inputs;
  return acc;
}, {});

/* returns: { destination: [module.attributes, ...], ... } */
export const ACTIVE_MODULES_BY_CATEGORY = ACTIVE_MODULE_ENTRIES.reduce(
  (acc, [key, { attributes }]) => {
    const { category } = attributes;
    acc[category] = acc[category] || [];
    acc[category].push({ ...attributes, slug: key });
    return acc;
  },
  MODULE_CATEGORIES_ORDERED.reduce((acc, category) => {
    acc[category] = [];
    return acc;
  }, {})
);

/* returns: { 'module:slug': module.attributes, ... } */
export const COMING_SOON_MODULE_ATTRIBUTES = COMING_SOON_MODULES_ENTRIES.reduce((acc, [key, { attributes }]) => {
  acc[key] = attributes;
  return acc;
}, {});

export const LIMITED_MODULE_ATTRIBUTES = LIMITED_MODULES_ENTRIES.reduce((acc, [key, { attributes }]) => {
  acc[key] = attributes;
  return acc;
}, {});
