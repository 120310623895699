import { Sprite } from '@inlet/react-pixi';
import { forwardRef } from 'react';

import withCanvasMovable from '@feature/studio/canvas/CanvasMovable';

const CanvasImagePlayer = forwardRef((props, ref) => {
  const { resource, visible, x, y, width, height, alpha, zIndex, angle } = props;
  if (!resource) {
    return null;
  }

  return (
    <Sprite
      ref={ref}
      anchor={0.5}
      image={resource.source}
      visible={visible}
      x={x}
      y={y}
      width={width}
      height={height}
      alpha={alpha}
      zIndex={zIndex}
      angle={angle}
    />
  );
});

export default withCanvasMovable(CanvasImagePlayer);
