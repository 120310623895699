import cn from 'classnames';
import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import '@css/Workspace.css';

import Automate from '@pages/Automate';
import Connect from '@pages/Connect';

import WorkspaceActionsLeft from '@feature/studio/toolbar/WorkspaceActionsLeft';
import WorkspaceActionsRight from '@feature/studio/toolbar/WorkspaceActionsRight';
import WorkspaceCanvas from '@feature/studio/workspace/WorkspaceCanvas';
import WorkspaceSidebar from '@feature/studio/workspace/WorkspaceSidebar';
import WorkspaceTimelineReactable from '@feature/studio/workspace/WorkspaceTimelineReactable';
import WorkspaceTopbar from '@feature/studio/workspace/WorkspaceTopbar';

import { sdkOptionsSelector } from '@store/atoms/SdkState';

import useStudioShortcuts from '@hooks/useStudioShortcuts';

function Workspace({ templateLoading }) {
  const { isSdkEnabled, isTimeline, isSidebar } = useRecoilValue(sdkOptionsSelector);
  const { page } = useParams();

  useStudioShortcuts();

  return (
    <div className="workspace" data-testid="studio-workspace">
      {!isSdkEnabled && <WorkspaceTopbar />}

      <div className={cn('workspace__main', { invisible: Boolean(page) })}>
        {isSidebar && (
          <div className="workspace__left">
            <WorkspaceSidebar />
          </div>
        )}

        <div className="workspace__right">
          {!isSdkEnabled && (
            <div className="workspace__actionbar">
              <WorkspaceActionsLeft />
              <WorkspaceActionsRight />
            </div>
          )}

          <WorkspaceCanvas />
          {isTimeline && <WorkspaceTimelineReactable templateLoading={templateLoading} />}
        </div>
      </div>

      <div className={cn('workspace__page', { show: page })}>
        {page === 'connect' && <Connect />}
        {page === 'automate' && <Automate />}
      </div>
    </div>
  );
}

export default memo(Workspace);
