import { Scrollbar } from 'react-scrollbars-custom';
import { v4 as uuid } from 'uuid';

import PanelMediaListing from '@feature/studio/panel/PanelMediaListing';
import PanelTemplateMediaListing from '@feature/studio/panel/PanelTemplateMediaListing';

import ButtonElement from '@components/atoms/ButtonElement';
import AssetSourceInput from '@components/controls/AssetSourceInput';

import { useAddClipToTimelineCallback } from '@store/studio/Media';

function PanelCaptionDefault() {
  const addClipToTimeline = useAddClipToTimelineCallback();

  const onAddEmptyCaption = () => {
    addClipToTimeline({ data: { placeholder: true }, type: 'caption' });
  };

  return (
    <div className="workspace__panel-inner">
      <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
        <div className="mb-4">Add Caption</div>
        <AssetSourceInput
          type="caption"
          accepts=".srt,.vtt"
          linkUploadEnabled={false}
          overrideEnabled={false}
          requestOptions={{
            filename: `${uuid()}.srt`,
          }}
        />
        <ButtonElement variant="light" handleEvent={onAddEmptyCaption} className="panel-button w-100 mt-2">
          Add Caption Placeholder
        </ButtonElement>
        <PanelTemplateMediaListing type="caption" />
        <PanelMediaListing type="caption" />
      </Scrollbar>
    </div>
  );
}

export default PanelCaptionDefault;
