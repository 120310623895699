import { Container, Graphics, Text } from '@inlet/react-pixi';
import { isNumber } from 'lodash-es';
import { TextStyle } from 'pixi.js';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import withCanvasMovable from '@feature/studio/canvas/CanvasMovable';

import useFontCheck from '@hooks/useFontCheck';

import { COLOR, FONT_FAMILY, TEXT_ALIGN } from '@constants/TextAssetDefaults';

import formatWebGlColor from '@utils/formatWebGlColor';

const MIN_FONT_SIZE = 1;

const BASE_AUTO_FONT_WIDTH_RATIO = 0.9;
const MIN_AUTO_FONT_SIZE = 8;
const MAX_AUTO_FONT_SIZE = 512;
const LINE_SPACING_RATIO = 0.5;
const ALLOWANCE_RATIO = 0.3;

const getInitialFontSize = (fontSize) => {
  const fontSizeInt = parseInt(fontSize);
  if (!isNumber(fontSizeInt) || fontSizeInt < MIN_FONT_SIZE) {
    return MIN_FONT_SIZE;
  }
  return Math.max(MIN_FONT_SIZE, fontSizeInt);
};

/**
 * @type {(content: string, boundingBox: { width: number; height: number }, initialSize: number) => number}
 */
const calculateFontSize = (content, boundingBox, initialSize) => {
  let fontSize = initialSize;
  const maxCapacity = boundingBox.width * boundingBox.height;
  while (fontSize > MIN_AUTO_FONT_SIZE) {
    // TODO: opentype algo - glyph.advanceWidth * fontSize / font.unitsPerEm;
    const fontWidth = fontSize * BASE_AUTO_FONT_WIDTH_RATIO;
    const fontHeight = fontSize;
    const lineHeight = fontHeight + fontHeight * LINE_SPACING_RATIO;
    const words = content.split(' ');
    let currentLineWidth = 0;
    let totalHeight = lineHeight;
    words.forEach((word) => {
      const wordWidth = word.length * fontWidth;
      if (currentLineWidth + wordWidth > boundingBox.width) {
        totalHeight += lineHeight;
        currentLineWidth = 0;
      }
      currentLineWidth += wordWidth + fontWidth;
    });
    let capacity = totalHeight * boundingBox.width;
    capacity += capacity * ALLOWANCE_RATIO;
    if (capacity <= maxCapacity) break;
    fontSize -= 1;
  }
  return Math.min(Math.max(MIN_AUTO_FONT_SIZE, fontSize), MAX_AUTO_FONT_SIZE);
};

const CanvasHtmlPlayer = forwardRef((props, ref) => {
  const { clip, visible, x, y, width, height, alpha, scale, zIndex, angle } = props;
  const isFontLoaded = useFontCheck(clip['asset:fontFamily']);

  /** @type {ReturnType<typeof import('react').useState<{ [key: string]: any }>>} */
  const [textAnchorX, setTextAnchorX] = useState(0.5);
  const [textAnchorY, setTextAnchorY] = useState(0.5);
  const [textOffsetX, setTextOffsetX] = useState(0);
  const [textOffsetY, setTextOffsetY] = useState(0);

  // @ts-ignore
  const {
    ['asset:text']: text,
    ['asset:background']: background,
    ['asset:color']: color,
    ['asset:textAlign']: textAlign,
    ['asset:fontFamily']: fontFamily,
    ['asset:fontSize']: fontSize,
    ['asset:fontWeight']: fontWeight,
    ['asset:fontStyle']: fontStyle,
    ['asset:fontVariant']: fontVariant,
    ['asset:lineHeight']: lineHeight,
    ['asset:position']: htmlPosition,
  } = clip;

  let dynamicFontSize = getInitialFontSize(fontSize);

  let bgColor = null;
  let bgOpacity = 0.0001; // if bgOpacity is 0, element is not interactive, can not be dragged.

  // todo: check background is valid hex color - #fff, #ffffff or #ccffffff
  if (background && background !== 'transparent') {
    bgColor = formatWebGlColor(background);
    bgOpacity = 1;
  }

  const boundingBox = useCallback(
    (graphics) => {
      graphics.clear();
      graphics.beginFill(bgColor, bgOpacity);
      graphics.drawRect(-width / 2, -height / 2, width, height);
      graphics.endFill();
    },
    [bgColor, bgOpacity, width, height]
  );
  const boundingBoxMaskRef = useRef();

  useEffect(() => {
    switch (textAlign) {
      case 'left':
        setTextAnchorX(0);
        setTextOffsetX(-width / 2);
        break;
      case 'right':
        setTextAnchorX(1);
        setTextOffsetX(width / 2);
        break;
      default:
        setTextAnchorX(0.5);
        setTextOffsetX(0);
        break;
    }
  }, [textAlign, width]);

  useEffect(() => {
    switch (htmlPosition) {
      case 'center':
        setTextAnchorY(0.5);
        setTextOffsetY(0);
        break;
      case 'top':
        setTextAnchorY(0);
        setTextOffsetY(-height / 2);
        break;
      case 'bottom':
        setTextAnchorY(1);
        setTextOffsetY(height / 2);
        break;
      default:
        setTextAnchorY(0.5);
        setTextOffsetY(0);
        break;
    }
  }, [height, htmlPosition]);

  if (clip['asset:textScale'] === 'shrink') {
    dynamicFontSize = calculateFontSize(text, { width, height }, fontSize);
  }

  const textStyle = new TextStyle({
    align: textAlign || TEXT_ALIGN,
    fontFamily: fontFamily || FONT_FAMILY,
    fontSize: `${dynamicFontSize}px`,
    fontWeight: fontWeight || 'normal',
    fill: color || COLOR,
    fontStyle: fontStyle || 'normal',
    fontVariant: fontVariant || 'normal',
    lineHeight: lineHeight || dynamicFontSize * 1.3,
    wordWrap: true,
    wordWrapWidth: width || 1,
    padding: 100,
    textBaseline: 'alphabetic',
  });

  return (
    <Container
      ref={ref}
      anchor={0.5}
      visible={visible}
      x={x}
      y={y}
      width={width}
      height={height}
      alpha={alpha}
      scale={scale}
      zIndex={zIndex}
      angle={angle}
    >
      <Graphics draw={boundingBox} />
      <Graphics name="mask" draw={boundingBox} ref={boundingBoxMaskRef} />
      {text && isFontLoaded ? (
        <Text text={text} anchor={[textAnchorX, textAnchorY]} x={textOffsetX} y={textOffsetY} style={textStyle} />
      ) : null}
    </Container>
  );
});

export default withCanvasMovable(CanvasHtmlPlayer);
