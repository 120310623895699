import {
  IconApiPlayground,
  IconDesigns,
  IconRenders,
  IconTemplate,
  IconUploads,
  IconUtilities,
  IconWorkflow,
} from '@assets/icons';

export const sidebarTop = {
  Studio: [
    { label: 'My Templates', to: '/studio/overview', Icon: IconDesigns },
    { label: 'Template Gallery', to: '/studio/templates', Icon: IconTemplate },
  ],
  Resources: [
    { label: 'My Renders', to: '/apis/edit-api/assets', Icon: IconRenders },
    { label: 'My Uploads', to: '/apis/ingest-api/assets', Icon: IconUploads },
  ],
  Tools: [
    {
      label: 'API Playground',
      to: '/apis/playground/edit-api',
      Icon: IconApiPlayground,
    },
    {
      label: 'Workflows',
      baseUrl: '/workflows',
      to: { overview: '/overview' },
      Icon: IconWorkflow,
      subLinks: [
        { label: 'My Workflows', to: '/overview' },
        { label: 'Workflow Templates', to: '/templates' },
      ],
    },
    {
      label: 'Utilities',
      baseUrl: '/utilities',
      to: { overview: '/video-to-mp3' },
      Icon: IconUtilities,
      subLinks: [{ label: 'Video to MP3', to: '/video-to-mp3' }],
    },
  ],
};

export const sidebarBottom = [
  {
    label: 'API Keys',
    to: '/keys',
  },
  { label: 'Studio SDK', to: '/studio/sdk' },
  {
    label: 'Integrations',
    to: '/integrations',
  },
  {
    label: 'Documentation',
    to: 'https://shotstack.io/docs/guide/',
    external: true,
  },
];
