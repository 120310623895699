import InputText from '@components/controls/InputText';

const s3Options = [
  { label: 'region', placeholder: 'us-east-1' },
  { label: 'bucket', placeholder: 'my-bucket' },
  { label: 'prefix', placeholder: 'my-renders' },
  { label: 'filename', placeholder: 'my-file' },
  { label: 'acl', placeholder: 'public-read' },
];

function S3({ destination, setDestination }) {
  const handleOptionsChange = (event) => {
    const { name, value } = event.target;
    setDestination((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        [name]: value,
      },
    }));
  };

  return (
    <div className="d-flex flex-column gap-2">
      {s3Options.map(({ label, placeholder }) => (
        <InputText
          key={label}
          name={label}
          label={label}
          placeholder={placeholder}
          value={destination?.options?.[label]}
          handleChange={handleOptionsChange}
        />
      ))}
    </div>
  );
}

export default S3;
