import { Container as PixiContainer, Stage as PixiStage } from '@inlet/react-pixi';
import cn from 'classnames';
import { throttle } from 'lodash-es';
import * as PIXI from 'pixi.js';
import { useEffect, useRef } from 'react';
import { useRecoilBridgeAcrossReactRoots_UNSTABLE, useRecoilState, useRecoilValue } from 'recoil';

import '@css/Canvas.css';

import { TimelinePlaybackProvider } from '@context/TimelinePlaybackProvider';

import CanvasTrack from '@feature/studio/canvas/CanvasTrack';

import ErrorBoundary from '@components/error/ErrorBoundary';

import { backgroundState, trackIdsState } from '@store/atoms/EditState';
import { sdkOptionsSelector } from '@store/atoms/SdkState';
import { canvasStageState } from '@store/selectors/CanvasSelectors';
import { outputDimensionsSelector } from '@store/studio/Output';

import { TIMELINE_HEIGHT } from '@constants/Timeline';

if (window.__PIXI_INSPECTOR_GLOBAL_HOOK__) {
  window.__PIXI_INSPECTOR_GLOBAL_HOOK__.register({ PIXI });
}

PIXI.utils.skipHello();

function CanvasStage({ children }) {
  const stageRef = useRef(null);
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();
  const output = useRecoilValue(outputDimensionsSelector);
  const trackIds = useRecoilValue(trackIdsState);
  const backgroundColor = useRecoilValue(backgroundState);
  const { isSdkEnabled, isSidebar, isTimeline } = useRecoilValue(sdkOptionsSelector);
  const [canvas, setCanvas] = useRecoilState(canvasStageState({}));
  const tracks = (trackIds || []).slice().reverse();

  const updateCanvasSize = throttle(() => {
    if (stageRef.current) {
      let containerWidth = stageRef?.current?.clientWidth;
      let containerHeight = stageRef?.current?.clientHeight;

      if (isSdkEnabled) {
        if (!isSidebar && !isTimeline) {
          containerWidth = window.innerWidth;
          containerHeight = window.innerHeight;
        } else if (!isSidebar && isTimeline) {
          containerHeight = window.innerHeight - TIMELINE_HEIGHT;
        }
      }

      setCanvas({
        containerWidth,
        containerHeight,
      });
    }
  }, 200);

  useEffect(() => {
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [stageRef, output?.resolution, output?.size, setCanvas, isSidebar, isTimeline]);

  return (
    <div ref={stageRef} className="canvas" data-testid="canvas-stage">
      <div className={cn('canvas__inner', { 'canvas__inner--sdk': isSdkEnabled })}>
        {canvas.ready && (
          <ErrorBoundary message="Check your JSON is valid and correct and try again.">
            <PixiStage
              width={canvas.stage.width}
              height={canvas.stage.height}
              style={{ backgroundColor }}
              options={{
                autoDensity: true,
                resolution: window.devicePixelRatio,
                backgroundAlpha: 0,
                antialias: false,
                width: canvas.width,
                height: canvas.height,
              }}
            >
              <PixiContainer scale={canvas.stage.scale} sortableChildren>
                <RecoilBridge>
                  <TimelinePlaybackProvider>
                    {tracks.map((id, index) => (
                      <CanvasTrack key={id} id={id} index={index} />
                    ))}
                  </TimelinePlaybackProvider>
                </RecoilBridge>
              </PixiContainer>
            </PixiStage>
          </ErrorBoundary>
        )}
        {children}
      </div>
    </div>
  );
}

export default CanvasStage;
