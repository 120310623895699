import { memo, useRef } from 'react';

import { IconDragHorizontal } from '@assets/icons';

import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import TimelineHeader from '@feature/studio/timeline/TimelineHeader';
import TimelinePlayheadLine from '@feature/studio/timeline/TimelinePlayheadLine';
import TimelineScale from '@feature/studio/timeline/TimelineScale';
import TimelineTracks from '@feature/studio/timeline/TimelineTracks';

function TimelineTimecode() {
  const { timecode, duration } = useTimelinePlaybackContext();

  return (
    <div className="timeline__timecode">
      <span>
        {timecode} / {duration}
      </span>
    </div>
  );
}

function WorkspaceTimeline({ templateLoading, height, getRef }) {
  const tracksElmRef = useRef(null);

  return (
    <div style={{ height }} className={`workspace__timeline ${templateLoading ? 'loading' : ''}`}>
      <div ref={getRef} className="timeline__resize-handle">
        <div className="timeline__resize-notch">
          <IconDragHorizontal />
        </div>
      </div>
      <div className="workspace__toolbar">
        <TimelineTimecode />
        <TimelineScale />
      </div>
      <div className="workspace__tracks" ref={tracksElmRef}>
        <TimelineHeader />
        <div id="tracks-container" className="tracks-container">
          <TimelinePlayheadLine />
          <TimelineTracks tracksElmRef={tracksElmRef} />
        </div>
      </div>
    </div>
  );
}

export default memo(WorkspaceTimeline);
