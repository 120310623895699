import { useRedo, useUndo } from '@context/RecoilUndo';

import KeyboardHelpButton from '@components/controls/KeyboardHelpButton';
import UndoRedoButtons from '@components/controls/UndoRedoButtons';

function WorkspaceActionsLeft() {
  const { undo, canUndo } = useUndo();
  const { redo, canRedo } = useRedo();

  return (
    <div className="d-flex gap-2">
      <UndoRedoButtons undo={undo} undoDisabled={!canUndo()} redo={redo} redoDisabled={!canRedo()} />
      <KeyboardHelpButton />
    </div>
  );
}

export default WorkspaceActionsLeft;
