import { createClipAnimation } from '@animation/animate';
import { roundKeyframeValue } from '@animation/keyframe';
import { getMotionEffectKeyframes } from '@animation/motion';
import { getTransitionKeyframes } from '@animation/transition';
import _ from 'lodash-es';
import { atomFamily, selectorFamily, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { processKeyframesForAnimation } from '@api/transform/utils/keyframes';

import { canvasState } from '@store/atoms/CanvasState';
import { clipsFamily } from '@store/atoms/EditState';
import { playheadState } from '@store/atoms/PlayheadState';

import setPrecision from '@utils/math/setPrecision';

const get = (selector, snapshot) => snapshot.getLoadable(selector).contents;

export const keyframeInterpolationAtomFamily = atomFamily({
  key: 'keyframes/interpolationAtomFamily',
  default: {
    'offset:x': 'linear',
    'offset:y': 'linear',
    opacity: 'liner',
    scale: 'linear',
  },
});

export const keyframeEasingAtomFamily = atomFamily({
  key: 'keyframes/easingAtomFamily',
  default: {
    'offset:x': undefined,
    'offset:y': undefined,
    opacity: undefined,
    scale: undefined,
  },
});

export const keyframeTimeAtomFamily = atomFamily({
  key: 'keyframes/timeAtomFamily',
  default: 0,
});

export const keyframeOffsetXAtomFamily = atomFamily({
  key: 'keyframes/offsetXAtomFamily',
  default: undefined,
});

export const keyframeOffsetYAtomFamily = atomFamily({
  key: 'keyframes/offsetYAtomFamily',
  default: undefined,
});

export const keyframeOpacityAtomFamily = atomFamily({
  key: 'keyframes/opacityAtomFamily',
  default: undefined,
});

export const keyframeScaleAtomFamily = atomFamily({
  key: 'keyframes/scaleAtomFamily',
  default: undefined,
});

export const clipKeyframeIdsAtomFamily = atomFamily({
  key: 'clipKeyframeIdsAtomFamily',
  default: [],
});

export const keyframeSelectorFamily = selectorFamily({
  key: 'keyframes/selectorFamily',
  get:
    (keyframeId) =>
    ({ get }) => {
      return {
        time: get(keyframeTimeAtomFamily(keyframeId)),
        'offset:x': get(keyframeOffsetXAtomFamily(keyframeId)),
        'offset:y': get(keyframeOffsetYAtomFamily(keyframeId)),
        opacity: get(keyframeOpacityAtomFamily(keyframeId)),
        scale: get(keyframeScaleAtomFamily(keyframeId)),
        interpolation: get(keyframeInterpolationAtomFamily(keyframeId)),
        easing: get(keyframeEasingAtomFamily(keyframeId)),
      };
    },
});

export const clipKeyframesSelectorFamily = selectorFamily({
  key: 'clipKeyframesSelectorFamily',
  get:
    (clipId) =>
    ({ get }) => {
      const keyframeIds = get(clipKeyframeIdsAtomFamily(clipId));
      const keyframes = keyframeIds.map((kfId) => {
        const keyframe = get(keyframeSelectorFamily(kfId));
        return { id: kfId, time: roundKeyframeValue(keyframe.time), ...keyframe };
      });
      return keyframes;
    },
});

export const clipKeyframesPropertiesEnabledSelectorFamily = selectorFamily({
  key: 'clipKeyframesPropertiesEnabledSelectorFamily',
  get:
    (clipId) =>
    ({ get }) => {
      const keyframes = get(clipKeyframesSelectorFamily(clipId));
      const enabledProperties = keyframes.reduce((acc, kf) => {
        const props = _.omit(kf, ['id', 'time', 'interpolation', 'easing']);
        Object.keys(props).forEach((key) => {
          if (props[key] !== undefined && !acc.includes(key)) {
            acc.push(key);
          }
        });
        return acc;
      }, []);
      return enabledProperties;
    },
});

export const activeClipKeyframeIdSelectorFamily = selectorFamily({
  key: 'activeClipKeyframeIdSelectorFamily',
  get:
    (clipId) =>
    ({ get }) => {
      const clip = get(clipsFamily(clipId));
      const keyframes = get(clipKeyframesSelectorFamily(clipId));
      const playhead = get(playheadState);
      const activeKeyframe = keyframes.find((kf) => {
        const timeDiff = Math.abs(kf.time - roundKeyframeValue(playhead - clip.start));
        return timeDiff <= 0.02;
      });
      return activeKeyframe?.id;
    },
});

export const clipEffectKeyframesSelectorFamily = selectorFamily({
  key: 'clipEffectKeyframesSelectorFamily',
  get:
    (clipId) =>
    ({ get }) => {
      const clip = get(clipsFamily(clipId));
      const canvas = get(canvasState);
      return getMotionEffectKeyframes({ clip, canvas });
    },
});

export const clipTransitionKeyframesSelectorFamily = selectorFamily({
  key: 'clipTransitionKeyframesSelectorFamily',
  get:
    (clipId) =>
    ({ get }) => {
      const clip = get(clipsFamily(clipId));
      const canvas = get(canvasState);
      return getTransitionKeyframes({ clip, canvas });
    },
});

export const clipKeyframesForAnimationSelectorFamily = selectorFamily({
  key: 'clipKeyframesForAnimationSelectorFamily',
  get:
    (clipId) =>
    ({ get }) => {
      const customKeyframes = get(clipKeyframesSelectorFamily(clipId));
      if (customKeyframes.length > 0) {
        const customKeyframesForAnimation = processKeyframesForAnimation(customKeyframes);
        return { count: customKeyframes.length, values: customKeyframesForAnimation, custom: true };
      }
      const effectKeyframes = get(clipEffectKeyframesSelectorFamily(clipId));
      const transitionKeyframes = get(clipTransitionKeyframesSelectorFamily(clipId));

      const combinedKeyframes = [...effectKeyframes, ...transitionKeyframes];
      if (combinedKeyframes.length > 0) {
        return { count: combinedKeyframes.length, values: combinedKeyframes, custom: false };
      }
      return { count: 0, values: [], custom: false };
    },
});

export const hydrateClipKeyframesCallback = (callbackArgs) => {
  const { set } = callbackArgs;
  return (clipId, keyframes) => {
    const keyframeIds = [];

    Object.entries(keyframes).forEach(([time, properties]) => {
      const keyframeId = uuid();
      set(keyframeTimeAtomFamily(keyframeId), Number(time));
      set(keyframeOffsetXAtomFamily(keyframeId), properties?.['offset:x']?.value);
      set(keyframeOffsetYAtomFamily(keyframeId), properties?.['offset:y']?.value);
      set(keyframeOpacityAtomFamily(keyframeId), properties?.opacity?.value);
      set(keyframeScaleAtomFamily(keyframeId), properties?.scale?.value);
      set(keyframeInterpolationAtomFamily(keyframeId), {
        'offset:x': properties?.['offset:x']?.interpolation,
        'offset:y': properties?.['offset:y']?.interpolation,
        opacity: properties?.['opacity']?.interpolation,
        scale: properties?.['scale']?.interpolation,
      });
      set(keyframeEasingAtomFamily(keyframeId), {
        'offset:x': properties?.['offset:x']?.easing,
        'offset:y': properties?.['offset:y']?.easing,
        opacity: properties?.['opacity']?.easing,
        scale: properties?.['scale']?.easing,
      });
      keyframeIds.push(keyframeId);
    });

    set(clipKeyframeIdsAtomFamily(clipId), keyframeIds);
  };
};

export const copyPasteClipKeyframesCallback = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return (fromClipId, toClipId) => {
    const fromKeyframes = get(clipKeyframesSelectorFamily(fromClipId), snapshot);

    const keyframeIds = [];

    fromKeyframes.forEach((keyframe) => {
      const newKeyframeId = uuid();
      set(keyframeTimeAtomFamily(newKeyframeId), keyframe.time);
      set(keyframeOffsetXAtomFamily(newKeyframeId), keyframe['offset:x']);
      set(keyframeOffsetYAtomFamily(newKeyframeId), keyframe['offset:y']);
      set(keyframeOpacityAtomFamily(newKeyframeId), keyframe.opacity);
      set(keyframeScaleAtomFamily(newKeyframeId), keyframe.scale);
      set(keyframeInterpolationAtomFamily(newKeyframeId), keyframe.interpolation);
      set(keyframeEasingAtomFamily(newKeyframeId), keyframe.easing);
      keyframeIds.push(newKeyframeId);
    });

    set(clipKeyframeIdsAtomFamily(toClipId), keyframeIds);
  };
};

export const addClipKeyframeCallback = (clipId) => (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return ({ time, props } = {}) => {
    const clip = get(clipsFamily(clipId), snapshot);
    const playhead = get(playheadState, snapshot);
    const newTime = roundKeyframeValue((time ? time : playhead) - clip.start);

    const newKeyframeId = uuid();
    const keyframes = get(clipKeyframesForAnimationSelectorFamily(clipId), snapshot);
    const animationValues = createClipAnimation({ playhead, keyframes, clip });
    const values = { ...animationValues, ...props };

    set(keyframeTimeAtomFamily(newKeyframeId), setPrecision(newTime, 2));
    set(keyframeOffsetXAtomFamily(newKeyframeId), setPrecision(values['offset:x'] || 0, 3));
    set(keyframeOffsetYAtomFamily(newKeyframeId), setPrecision(values['offset:y'] || 0, 3));
    set(keyframeOpacityAtomFamily(newKeyframeId), setPrecision(values.opacity || 1, 3));
    set(keyframeScaleAtomFamily(newKeyframeId), setPrecision(values.scale || 1, 3));
    set(keyframeInterpolationAtomFamily(newKeyframeId), {
      'offset:x': 'linear',
      'offset:y': 'linear',
      opacity: 'linear',
      scale: 'linear',
    });
    set(keyframeEasingAtomFamily(newKeyframeId), {
      'offset:x': undefined,
      'offset:y': undefined,
      opacity: undefined,
      scale: undefined,
    });
    set(clipKeyframeIdsAtomFamily(clipId), (prevState) => [...prevState, newKeyframeId]);
  };
};

const removeClipKeyframeCallback = (clipId) => (callbackArgs) => {
  const { set, reset, snapshot } = callbackArgs;
  return (time) => {
    const clip = get(clipsFamily(clipId), snapshot);
    const playhead = get(playheadState, snapshot);
    const newTime = (time ? time : playhead) - clip.start;

    const keyframes = get(clipKeyframesSelectorFamily(clipId), snapshot);
    const keyframeToRemove = keyframes.find((kf) => kf.time === roundKeyframeValue(newTime));

    if (keyframeToRemove) {
      const keyframeId = keyframeToRemove.id;
      set(clipKeyframeIdsAtomFamily(clipId), (prevState) => prevState.filter((kfId) => kfId !== keyframeId));
      reset(keyframeTimeAtomFamily(keyframeId));
      reset(keyframeOffsetXAtomFamily(keyframeId));
      reset(keyframeOffsetYAtomFamily(keyframeId));
      reset(keyframeOpacityAtomFamily(keyframeId));
      reset(keyframeScaleAtomFamily(keyframeId));
      reset(keyframeInterpolationAtomFamily(keyframeId));
      reset(keyframeEasingAtomFamily(keyframeId));
    }
  };
};

const selectClipKeyframeCallback = (clipId) => (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return (time) => {
    const clip = get(clipsFamily(clipId), snapshot);
    set(playheadState, time + clip.start);
  };
};

const cycleClipKeyframeCallback = (clipId) => (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return (direction) => {
    const clip = get(clipsFamily(clipId), snapshot);
    const playhead = get(playheadState, snapshot);
    const clipRelativeTime = roundKeyframeValue(playhead - clip.start);
    const keyframes = get(clipKeyframesSelectorFamily(clipId), snapshot);

    const adjacentKeyframe = [...keyframes]
      .sort((a, b) => (direction === 'previous' ? b.time - a.time : a.time - b.time))
      .find((kf) => (direction === 'previous' ? kf.time < clipRelativeTime : kf.time > clipRelativeTime));

    if (adjacentKeyframe) {
      set(playheadState, adjacentKeyframe.time + clip.start);
    }
  };
};

export const useHydrateClipKeyframes = () => useRecoilCallback(hydrateClipKeyframesCallback);
export const useAddClipKeyframe = (clipId) => useRecoilCallback(addClipKeyframeCallback(clipId));
export const useRemoveClipKeyframe = (clipId) => useRecoilCallback(removeClipKeyframeCallback(clipId));
export const useSelectClipKeyframe = (clipId) => useRecoilCallback(selectClipKeyframeCallback(clipId));
export const useCycleClipKeyframe = (clipId) => useRecoilCallback(cycleClipKeyframeCallback(clipId));
export const useCopyPasteClipKeyframes = () => useRecoilCallback(copyPasteClipKeyframesCallback);
