export const enabledKeyframeProperties = ['opacity', 'offset:x', 'offset:y', 'scale']; // 'rotation', 'skew', 'volume'
export const enabledKeyframePropertiesDefaults = {
  opacity: 1,
  'offset:x': 0,
  'offset:y': 0,
  scale: 1,
};

export const easeOptions = [
  { value: 'linear', text: 'Linear' },
  { value: 'ease', text: 'Ease' },
  { value: 'easeIn', text: 'Ease In' },
  { value: 'easeOut', text: 'Ease Out' },
  { value: 'easeInOut', text: 'Ease In Out' },
  { value: 'easeInSine', text: 'Ease In Sine' },
  { value: 'easeOutSine', text: 'Ease Out Sine' },
  { value: 'easeInOutSine', text: 'Ease In Out Sine' },
  { value: 'easeInQuad', text: 'Ease In Quad' },
  { value: 'easeOutQuad', text: 'Ease Out Quad' },
  { value: 'easeInOutQuad', text: 'Ease In Out Quad' },
  { value: 'easeInCubic', text: 'Ease In Cubic' },
  { value: 'easeOutCubic', text: 'Ease Out Cubic' },
  { value: 'easeInOutCubic', text: 'Ease In Out Cubic' },
  { value: 'easeInQuart', text: 'Ease In Quart' },
  { value: 'easeOutQuart', text: 'Ease Out Quart' },
  { value: 'easeInOutQuart', text: 'Ease In Out Quart' },
  { value: 'easeInQuint', text: 'Ease In Quint' },
  { value: 'easeOutQuint', text: 'Ease Out Quint' },
  { value: 'easeInOutQuint', text: 'Ease In Out Quint' },
  { value: 'easeInExpo', text: 'Ease In Expo' },
  { value: 'easeOutExpo', text: 'Ease Out Expo' },
  { value: 'easeInOutExpo', text: 'Ease In Out Expo' },
  { value: 'easeInCirc', text: 'Ease In Circ' },
  { value: 'easeOutCirc', text: 'Ease Out Circ' },
  { value: 'easeInOutCirc', text: 'Ease In Out Circ' },
  { value: 'easeInBack', text: 'Ease In Back' },
  { value: 'easeOutBack', text: 'Ease Out Back' },
  { value: 'easeInOutBack', text: 'Ease In Out Back' },
];
