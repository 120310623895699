import { useCallback, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import WorkflowCreate from '@feature/workflows/buttons/WorkflowCreateButton';
import WorkflowDeleteBulkButton from '@feature/workflows/buttons/WorkflowDeleteBulkButton';
import WorkflowListItems from '@feature/workflows/list/WorkflowListItems';

import LinkElement from '@components/atoms/LinkElement';

import useCreateWorkflow from '@hooks/useCreateWorkflow';

const clipboardSupported = 'clipboard' in navigator;

function OverviewWorkflows() {
  const history = useNavigate();
  const { handleCreate } = useCreateWorkflow({
    onSuccess: (workflowId) => history(`/workflows/${workflowId}/edit?isNew`),
  });

  const handlePaste = useCallback(async () => {
    try {
      const clipboardContent = await navigator.clipboard.readText();

      let pastedWorkflow;
      try {
        pastedWorkflow = JSON.parse(clipboardContent);
      } catch (error) {
        console.error('Failed to parse clipboard content as JSON:', error);
        return;
      }
      const isValidWorkflow = ['name', 'configuration'].every((key) => key in pastedWorkflow);
      if (isValidWorkflow) {
        const { name, configuration } = pastedWorkflow;
        handleCreate({
          name: `${name} (Copy)`,
          configuration,
        });
      } else {
        console.error('Invalid workflow pasted. Missing name or configuration fields.');
      }
    } catch (err) {
      console.error('Failed to read clipboard contents:', err);
    }
  }, [handleCreate]);

  useEffect(() => {
    const onKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'v') {
        handlePaste();
      }
    };

    if (clipboardSupported) {
      document.addEventListener('keydown', onKeyDown);
    }
    return () => {
      if (clipboardSupported) {
        document.removeEventListener('keydown', onKeyDown);
      }
    };
  }, [handlePaste]);
  return (
    <>
      <Card className="p-3 mb-10">
        <Card.Body>
          <Row>
            <Col className="mb-4" lg={7} xl={8}>
              <div className="max-w-screen-sm pr-2">
                <p className="uppercase-label">WORKFLOWS</p>
                <h1>Visual, No-Code Media Application Builder</h1>
                <p className="text-gray-500">
                  Quickly build applications and workflows with a visual tool. Connect our APIs with AI and scale your
                  media media production using webhooks or CSV files.
                </p>
                <LinkElement to="/workflows/templates" variant="light" type="button">
                  Workflow Templates
                </LinkElement>
              </div>
            </Col>
            <Col className="mb-4" lg={5} xl={4}>
              <div className="flex flex-col gap-2 mb-4">
                <WorkflowCreate tracking={{ Position: 'Workflows Overview' }} />
                <WorkflowDeleteBulkButton />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <WorkflowListItems />
    </>
  );
}

export default OverviewWorkflows;
