import { useRecoilState } from 'recoil';

import '@css/TimelineScale.css';

import { IconZoomIn } from '@assets/icons';

import { timelineScaleState } from '@store/atoms/TimelineState';

import { TIMELINE_SCALE_RANGE } from '@constants/Timeline';

function TimelineScale() {
  const [timelineScale, setTimelineScale] = useRecoilState(timelineScaleState);

  const handleScaleChange = (e) => {
    setTimelineScale(+e.target.value);
  };

  return (
    <div className="scale">
      <input
        step="2"
        min={TIMELINE_SCALE_RANGE[0]}
        max={TIMELINE_SCALE_RANGE[1]}
        onChange={handleScaleChange}
        type="range"
        defaultValue={timelineScale}
      />
      <div className="scale__icon">
        <IconZoomIn size={14} />
      </div>
    </div>
  );
}

export default TimelineScale;
